:root {
    /* Indicator For Team Potential */
    --left-value-current: 0;
    --left-value-target: 0;
    --background-color-current: green;
    --background-color-target: red;
    --low-color: #f14913;
    --medium-color: #fbaf00;
    --high-color: #43c770;
    --range-count: 7;
    --indicator-width: 135px;
}
  
.potential__range {
    color: #1d1d1d;    
    margin: 60px 30px;
    padding: 1.6rem 0;
}
.potential__range .range {
    display: flex;
    margin-bottom: 3.2rem;
}

.greyscale {
    filter: grayscale(100%);
}

.potential__range .range .item {
    width: calc(100% / var(--range-count));
    height: 30px;
    position: relative;
    background-color: rgba(200, 207, 213, 0.5);
}
.potential__range .range .item .indicator {
    width: var(--indicator-width);
    height: 32px;
    background-color: var(--background-color-current);
    border-top-color: var(--background-color-current);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.08);
    padding: 0px 15px;
    border-radius: 25px;
    position: absolute;
    left: calc(var(--left-value-current) - var(--indicator-width)/2);
    top: -45px;
    display: flex;
    align-items: center;
    justify-content: center;    
}
.rotate180 {
    transform: rotate(180deg);
}
.potential__range .range .item .indicator.bottom {
    top: 43px;
    box-shadow: 0 -14px 20px 0 rgba(0, 0, 0, 0.08);
    left: calc(var(--left-value-target) - var(--indicator-width)/2);
    background-color: var(--background-color-target);
    border-top-color: var(--background-color-target);
}
.potential__range .range .item .indicator .shadow180 {
    box-shadow: 0 -14px 20px 0 rgba(0, 0, 0, 0.08);
}
p.riskFonts {
    font-size: 1.3rem;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: white;
}
p.riskLevel {
    text-align: center;
    padding: 9px 0;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -95%);
}
.potential__range .range .item .indicator::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top-width: 12px;
    border-top-style: solid;
    border-top-color: inherit;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 133%);
}
.potential__range .range .item .indicator.rotate180::before {
    border-top-color: inherit;
}
.potential__range .range .item:not(:last-child) {
    margin-right: 4px;
}

.potential__range .range .item:first-child {
    border-radius: 25px 0px 0px 25px;
}

.potential__range .range .item:last-child {
    border-radius: 0px 25px 25px 0px;
}

.potential__range .range .item:nth-child(7) {
    background: #f14913;
}

.potential__range .range .item:nth-child(6) {
    background: #f17613;
}
.potential__range .range .item:nth-child(5) {
    background: #f19e13;
}
.potential__range .range .item:nth-child(4) {
    background: #f1b113;
}
.potential__range .range .item:nth-child(3) {
    background: #f1de13;
}
.potential__range .range .item:nth-child(2) {
    background: #ccd948;
}
.potential__range .range .item:nth-child(1) {
    background: #43c770;
}
