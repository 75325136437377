@import url('https://fonts.googleapis.com/css?family=Roboto|Open+Sans');


/* Styles */
.delay {
    opacity: 1;
    animation-name: delayOpacity;
    animation-iteration-count: 1;
    animation-duration: 1s;
}
@keyframes delayOpacity {
    0% {
        opacity: 0;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
}
.fade-in-slow {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
}

#background-image{
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
#app {
    font: 14px;
    color: #4d4d4d;
    min-height: 2000px;
    margin: 0 0 0 0;
    background-color: 'white';
}

/* Styles */

.appContent {
    font: 14px;
    color: #4d4d4d;
    min-width: 550px;
    margin: 0 auto;
    background-color: 'white';
}

.printContent {
    padding: 20px;
    background-color: #ffffff;
}

body {
    zoom: 1;
}
#highlight-g {
    color: #4E7EC2;
}

/*/Global Styling/*/

footer {
    /* padding-top: 40px; */
    background-color: #efefef;
    /* height: 100px; */
    margin-top: 100px;
    font-family: 'Rubik', sans-serif;
}

footer a {
    color: #2954a2;
}

#footer1,
#footer2,
#footer3 {
    display: flex;
    align-items: center;
}

#logo-container {
    display: flex;
    align-self: center;
    margin-left: 5px;
    padding: 10px;
}

nav {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 1em;
    text-align: right;
    grid-area: nav;
}

.nav > li {
    display: inline-block;
}

#nav-container {
    margin-top: 12px;
}

nav a,
button {
    margin: 23px;
    color: rgba(0, 0, 0, 0.95);
}

nav button {
    width: 90px;
}
.recharts-surface {
    overflow: visible !important;
}

/* h1 {
    font-family: 'PT Sans Caption', sans-serif;
    font-weight: 700;
} */

#h2-container h2 {
    font-weight: 600;
}

p {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    margin-top: 20px;
}

h2 {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
}

#icon1 {
    grid-area: icon1;
}

#icon2 {
    grid-area: icon2;
}

#icon3 {
    grid-area: icon3;
}

#icon1 h1,
#icon2 h1,
#icon3 h1 {
    font-size: 30px;
    margin-top: 5px;
}

#icon1,
#icon2,
#icon3 {
    font-family: 'Rubik', sans-serif;
}
#box1,
#box2,
#box3 {
    width: 300px;
    height: 300px;
    border: 7px solid #d7d2cb;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 2em;
    display: flex;
    align-items: center;
    padding: 10px;
}

#box1:hover,
#box2:hover,
#box3:hover {
    border: 7px solid #4E7EC2;
}
#icon-text-cont {
    margin-top: 10px;
}
#item1,
#item2,
#item3 {
    border: 4px solid #d7d2cb;
    padding: 15px;
    margin-bottom: 60px;
}
#item1:hover {
    border: 4px solid #4E7EC2;
}

#item2:hover,
#item3:hover {
    opacity: 0.5;
    cursor: not-allowed;
}
.verificationDropzone {
    height: 50px;
    width: 250px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
}

.verificationDropzoneDrag {
    background-color: rgb(250, 102, 102);
    border-style: solid;
}

.verificationDropzone p {
    margin-top: 3px;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}

input[type=number].withoutArrows::-webkit-inner-spin-button,
input[type=number].withoutArrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

/*/Code for the responsiveness/*/

/*/Desktop Version/*/

@media (min-width: 1017px) {
    #burger {
        display: none;
    }


    main h1 {
        font-size: 3em;
        padding: 8vh 40px 15px 35px;
        margin-top: 20px;
    }
    #h2-container {
        margin: 70px 0px;
        text-align: center;
        padding: 3.5vw;
    }
    #icons-container {
        margin: 40px;
        justify-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'icon1 icon2 icon3';
    }
    #icon1,
    #icon2,
    #icon3 {
        padding: 40px;
    }

    #footer-container {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
    }
}

/*/Ipad Version/*/

@media (min-width: 748px) and (max-width: 1016px) {
    #burger {
        display: none;
    }


    main h1 {
        font-size: 2.5em;
        padding: 60px 0;
        margin: 100px 20px 0 120px;
    }
    #h2-container {
        margin: 70px 0px;
        text-align: center;
        padding: 3.5vw;
    }
    #icons-container {
        justify-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'icon1 icon2 icon3';
    }
    #icon1,
    #icon2,
    #icon3 {
        padding: 40px;
    }

    #footer-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
    }
}

/*/Mobile Version/*/

@media (max-width: 747px) {
    #logo-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    #nav-container {
        width: 500;
        display: grid;
    }

    #header {
        display: none;
    }
    main h1 {
        font-size: 2em;
        margin: 50px 180px 0 20px;
    }


    #highlight-g {
        color: black;
    }
    #h2-container {
        width: 100vw;
        margin: 70px 0px;
        padding: 3.5vw;
        text-align: left;
    }
    #h2-container h2 {
        font-size: 1.5em;
    }
    #icons-container {
        width: 100vw;
        margin: 70px 0px;
        padding: 3.5vw;
        text-align: left;
    }
    #icon1,
    #icon2,
    #icon3 {
        padding: 0;
        margin-top: 80px;
    }

    footer {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    nav a,
    button {
        margin: 2px;
        border: none;
    }

    nav button {
        width: 20px;
    }

}


/* for printing */

div.page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

div.page.A4-portrait {
  width: 793px;
}

div.page.A4-landscape {
  width: 1123px;
  height: 793px;
}

@media print {
  .printContent {
    padding: 0;
  }
  div.page {
    margin: 0;
    box-shadow: none;
  }
  #nav-wrapper {
    display: none;
  }
  #footer-wrapper {
    display: none;
  }
  #report-injector {
    display: none;
  }
}
.noMarginArea p{
    margin:0px;
}
.noMarginArea h4.ui.header{
    margin:0px;
    margin-top:20px;
}

.space-between {
    display: flex;
    justify-content: space-between;
}
.descriptive-header {
  color: #7f8fa4;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.descriptive-text {
  color: #a0a0a0;
  font-size: 12px;
}
.table-header {
  color: #5c5d5d;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: Helvetica;
  margin-bottom: 0px;
}


/* Temp for resetting */

#page-name{
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
}
#page-actions-top{
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

a:hover{
    color:inherit;
}


  .fullscreen-enabled .full-screenable-node {
    background: #fff;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
.fullscreen-logo{
    display:none;
}
  .fullscreen-enabled .fullscreen-logo{
    display:block;
    position: absolute;
    top: 40;
    left: 40;
    width:105px;
  }
